<template>
  <div
    class="d-flex justify-center full-height"
    :class="{
      'flex-column align-center': $vuetify.breakpoint.smAndDown,
      'align-end ml-6': $vuetify.breakpoint.mdAndUp
    }"
  >
    <div class="d-flex justify-end align-center mt-6 mb-4" v-if="!$vuetify.breakpoint.mdAndUp">
      <div
        v-for="(tier, index) in tiersWithApy"
        :key="index"
        class="mobile-tier-image tier-icon cursor-pointer d-flex align-center justify-center"
        @click="pointingTier(index)"
      >
        <img
          :style="{
            'max-width:': tier.mobileImageOutlineSize + 'px !important',
            'max-height': tier.mobileImageOutlineSize + 'px !important'
          }"
          :src="tier.imageOutline"
          v-show="showingTierIndex === index"
        />
        <img
          :style="{
            'max-width:': tier.mobileImageSize + 'px !important',
            'max-height': tier.mobileImageSize + 'px !important'
          }"
          :src="tier.image"
          v-show="showingTierIndex !== index"
        />
        <img
          max-width="98px"
          class="mobile-current-tier-icon"
          v-if="currentTierIndex === index"
          :src="require('@/assets/current-tier-icon.svg')"
        />
      </div>
    </div>
    <div v-for="(tier, index) in tiers" :key="index">
      <div v-show="showingTierIndex === index">
        <TierCard
          :class="{ 'fading-from-bottom': isAnimating }"
          :tier="tiersWithApy[index]"
          :currentTierIndex="currentTierIndex"
          :showingTierIndex="showingTierIndex"
          :stakedToken="stakedToken"
          @click="pointingTier(index)"
        />
      </div>
    </div>
    <div class="d-flex justify-end align-center flex-column py-4 ml-8" v-if="$vuetify.breakpoint.mdAndUp">
      <div
        v-for="(tier, index) in tiersWithApy"
        :key="index"
        class="tier-image tier-icon cursor-pointer d-flex align-center justify-center"
        :style="{ 'margin-top': $vuetify.breakpoint.mdAndUp ? tier.marginTop : '' }"
        @click="pointingTier(index)"
      >
        <img
          :style="{
            'max-width:': tier.imageOutlineSize + 'px !important',
            'max-height': tier.imageOutlineSize + 'px !important'
          }"
          :src="tier.imageOutline"
          v-show="showingTierIndex === index"
        />
        <img
          :style="{
            'max-width:': tier.imageSize + 'px !important',
            'max-height': tier.imageSize + 'px !important'
          }"
          :src="tier.image"
          v-show="showingTierIndex !== index"
        />
        <img
          max-width="98px"
          class="current-tier-icon"
          v-if="currentTierIndex === index"
          :src="require('@/assets/current-tier-icon.svg')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { bnHelper } from '@/helpers/bignumber-helper'
import { FixedNumber } from '@ethersproject/bignumber'
import TierCard from './tier-card.vue'
import { findLastIndex, reverse } from 'lodash'
export default {
  components: {
    TierCard
  },
  props: {
    stakedToken: Object,
    apyConfigs: Array
  },
  computed: {
    tiersLength() {
      return this.tiers.length
    },
    tiersWithApy() {
      if (this.apyConfigs.length === 0 || !this.apyConfigs) return this.tiers
      return this.tiers.map((tier, index) => ({
        ...tier,
        amount: this.apyConfigs[this.apyConfigs.length - index - 1].amount,
        apy: this.apyConfigs[this.apyConfigs.length - index - 1].apy
      }))
    },
    currentTierIndex() {
      if (this.apyConfigs.length === 0 || !this.apyConfigs) return 0
      const currentTierIndex = this.tiersWithApy.findIndex(tier => bnHelper.gte(this.stakedToken, tier.amount))
      return currentTierIndex
    }
  },
  methods: {
    async pointingTier(index) {
      if (index === this.showingTierIndex) return
      this.isAnimating = true
      this.showingTierIndex = index
      await this.sleep(400)
      this.isAnimating = false
    },
    async sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
  },
  watch: {
    currentTierIndex(index) {
      if (index !== -1) this.showingTierIndex = index
    }
  },
  data() {
    return {
      isAnimating: false,
      tiers: [
        {
          name: 'Platinum tier',
          image: require('@/assets/big-platinum.png'),
          imageOutline: require('@/assets/outline-platinum.png'),
          imageSize: '98',
          imageOutlineSize: '86',
          mobileImageSize: '78',
          mobileImageOutlineSize: '68',
          marginTop: '0px'
        },
        {
          name: 'Gold tier',
          image: require('@/assets/big-gold.png'),
          imageOutline: require('@/assets/outline-gold.png'),
          imageSize: '92',
          imageOutlineSize: '86',
          mobileImageSize: '68',
          mobileImageOutlineSize: '64',
          marginTop: '12px'
        },
        {
          name: 'Silver tier',
          image: require('@/assets/big-silver.png'),
          imageOutline: require('@/assets/outline-silver.png'),
          imageSize: '102',
          imageOutlineSize: '72',
          mobileImageSize: '78',
          mobileImageOutlineSize: '56',
          marginTop: '12px'
        },
        {
          name: 'Bronze tier',
          image: require('@/assets/big-bronze.png'),
          imageOutline: require('@/assets/outline-bronze.png'),
          imageSize: '84',
          imageOutlineSize: '75',
          mobileImageSize: '70',
          mobileImageOutlineSize: '64',
          marginTop: '6px'
        }
      ],
      showingTierIndex: 3
    }
  }
}
</script>

<style scoped>
.tier-icon {
  position: relative;
}
.current-tier-icon {
  position: absolute;
  right: -6px;
  top: 0px;
}
.mobile-current-tier-icon {
  position: absolute;
  right: 0px;
  top: 0px;
}
.tier-image {
  width: 98px;
  height: 98px;
}
.mobile-tier-image {
  width: 86px;
  height: 86px;
}
</style>
