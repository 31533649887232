<template>
  <v-card
    id="tier-card"
    class="text-center elevation-0 d-flex flex-column justify-start pt-10"
    width="340px"
    height="446px"
    contain
    v-bind="this.$attrs"
  >
    <img
      class="current-tier"
      v-if="showingTierIndex === currentTierIndex"
      :src="require('@/assets/currentTier.svg')"
      style="width: 153px; height: 40px"
      contain
    />
    <img class="mx-auto" style="max-width: 220px; max-height: 220px" :src="tier.image" contain />
    <div class="d-flex flex-column align-center justify-end mt-6">
      <div class="tier-title">{{ tier.name }}</div>
      <div class="tier-amount accent--text mt-2">
        {{ tier.amount | formatNumber(0) }} <span class="tier-unit">$UM</span>
      </div>
      <div class="d-flex align-center justify-space-between tier-apy mt-2">
        <v-icon class="mr-3" :size="8">mdi-circle</v-icon>
        <div>{{ tier.apy }}% APR</div>
        <v-icon class="ml-3" :size="8">mdi-circle</v-icon>
      </div>
    </div>
  </v-card>
</template>

<script>
import { FixedNumber } from '@ethersproject/bignumber'

export default {
  props: {
    tier: Object,
    stakedToken: Object,
    currentTierIndex: Number,
    showingTierIndex: Number,
  },
  computed: {
    isAchievedTier() {
      if (this.currentTierIndex === -1) return false
      return this.showingTierIndex >= this.currentTierIndex
    },
    requiredAmountToUpgrade() {
      if (!this.tier.amount) return '0'
      return FixedNumber.from(this.tier.amount).subUnsafe(this.stakedToken).toString()
    },
  },
}
</script>

<style scoped>
#tier-card {
  background: url('../../../assets/tier-card.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.tier-text {
  padding-bottom: 23%;
}
.tier-apy {
  font-style: normal;
  font-weight: 900;
  font-size: 18px !important;
  line-height: 24px;
}
.tier-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px !important;
  line-height: 130%;
  letter-spacing: 0.005em;
  text-transform: uppercase;
}
.tier-amount {
  font-style: normal;
  font-weight: 900;
  font-size: 32px !important;
  line-height: 24px;
}
.tier-unit {
  font-style: normal;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 130%;
}
.tier-description {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 130%;
}
.tier-description-number {
  font-style: normal;
  font-weight: bolder;
  font-size: 20px !important;
  line-height: 130%;
}
.current-tier {
  position: absolute;
  left: 8px;
  top: 16px;
}
</style>